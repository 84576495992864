.content {
  /* Styles to ensure content is readable and visually appealing */
  text-align: center;
  padding-top: 20vh; /* Adjust based on your layout */
  color: white; /* Choose a color that stands out against your GIF */
}



.neonText {
  color: #53cadf;
  text-shadow: 0 0 5px #0d4853, 0 0 10px #0d4853;
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center; 
  justify-content: center; 
  height: 100vh;
  padding: 0 20px; 
}


/* For mobile screens */
@media only screen and (max-width: 600px) {
  .neonText {
    font-size: 16px; /* Smaller font size */
    padding: 0 10px; /* Less padding */
  }
}