/* QuestionPopup.css */
.popup-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px; /* Smooth corners */
    z-index: 100;
    width: 300px; /* Adjust based on content size */
  }
  
  .popup-input,
  .popup-select {
    width: 100%;
    padding: 8px;
    margin-top: 8px;
    border-radius: 4px; /* Smooth corners for inputs and select */
    border: 1px solid #ccc;
    box-sizing: border-box; /* Ensures padding does not increase the size */
  }
  
  .popup-button {
    display: inline-block;
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px; /* Smooth corners for button */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .popup-button:hover {
    background-color: #0056b3;
  }
  